import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ConfirmDialog from '../confirm-dialog';
import { Box } from '@mui/material';
import { StlViewer } from 'react-stl-viewer';
const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '70vh',
    margin: '0 auto',
};
function ViewFile({ viewSTL, onClose, isStl, currentUrl }) {
    return (_jsx(ConfirmDialog, { cancelText: `Fermer`, open: viewSTL, onClose: onClose, fullScreen: true, title: `Aperçu du fichier ${isStl ? 'STL' : ''}`, sx: { maxWidth: '800px', margin: 'auto' }, content: _jsx(_Fragment, { children: currentUrl && isStl ? (_jsx(StlViewer, { style: style, orbitControls: true, shadows: true, url: currentUrl })) : (_jsx(Box, { component: "img", src: currentUrl, sx: {
                    objectFit: 'contain',
                    maxHeight: '70vh',
                    margin: '0 auto',
                    mixBlendMode: 'multiply',
                } })) }), action: _jsx(_Fragment, {}) }));
}
export default ViewFile;
